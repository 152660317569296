<template>
  <div class="container">
    <!-- 模板消息列表 -->
    <div class="search" style="display:flex">
      <el-form ref="searchform" style="flex:1" :inline="true" :model="search" class="demo-form-inline">
        <el-form-item>
          <el-input v-model.trim="search.kname" @change="Search" placeholder="消息名称"></el-input>
        </el-form-item>
      </el-form>
      <div>
        <router-link :to="'/message/templatemessageadd/'+0">
          <el-button type="primary" size="small">新建</el-button>
        </router-link>
      </div>
    </div>

    <el-table border v-bind:data="list">
      <el-table-column label="模板名称">
        <template v-slot="scope">
          <span>{{ scope.row.name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="终端编号">
        <template v-slot="scope">
          <span>{{ scope.row.appid }}</span>
        </template>
      </el-table-column>
      <el-table-column label="是否重复">
        <template v-slot="scope">
          <span>{{ scope.row.is_repeat==1?'重复':'不重复' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="350px">
        <template v-slot="scope">
          <!-- <el-button type="info" size="mini" @click="Distribution(scope.row)">下发情况</el-button> -->
          <router-link v-if="buttonshow" style="margin-right:10px" :to="'/message/distributetsk/' + scope.row.code">
            <el-button type="info" size="mini">任务列表</el-button>
          </router-link>
          <el-button type="danger" size="mini" @click="DeleteTempMsg(scope.row)">删除</el-button>
          <router-link :to="'/message/templatemessageadd/'+scope.row.code" style="margin-left:10px;margin-right:10px">
            <el-button type="primary" size="mini">详情</el-button>
          </router-link>
          <router-link v-if="buttonshow" :to="'/message/mespush/'+scope.row.code">
            <el-button type="info" size="mini" >消息推送</el-button>
          </router-link>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @current-change="handleCurrentChange"
      v-model:currentPage="search.page"
      :page-size="search.limit"
      layout="total, prev, pager, next"
      :total="total">
    </el-pagination>

  </div>
</template>

<script type="text/ecmascript-6">
export default {
  name: '',
  props: {},
  setup () {
    return {
    }
  },
  mixins: [],
  components: {},
  data () {
    return {
      search: {
        page: 1,
        limit: 10,
        kname: ''
      },
      list: [],
      total: 0,
      applist: [],
      buttonshow: true
    }
  },
  computed: {},
  watch: {},
  methods: {
    GetAppList() {
      this.api.ClientList({
        page: 1,
        limit: 100,
      }).then(res => {
        if (res.data.code == 200) {
          this.applist = res.data.data.data
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    handleCurrentChange(val) {
      this.search.page = val
      this.Search()
    },
    Search() {
      this.api.TemplateList(this.search).then(res => {
        if (res.data.code == 200) {
          this.list = res.data.data.data
          this.total = res.data.data.page_info.total
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    DeleteTempMsg(item) {
      this.$confirm('是否删除消息模板?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 删除请求
        this.api.TemplateDelete({
          code: item.code,
        }).then(res => {
           if (res.data.code == 200) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.Search()
            return
          }
          this.$message.error('删除失败' + res.data.msg)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消删除'
        });
      })
    },
    Distribution() {},
    Translate() {},
  },
  filters: {},
  mounted () {
    if (localStorage.getItem('region_change')=='gb') {
      this.buttonshow = false
    } else {
      this.buttonshow = true
    }
    this.Search()
  },
  created () {
    this.GetAppList()
  }
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
</style>
